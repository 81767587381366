import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import {
	Inspection,
	InspectionQueryResponse,
	ResponseInspectionById,
	SelectModelQueryResponse,
	FileName,
	InspectionConfirmResponse,
	ConfirmResponseModel,
	SelectModel
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const INSPECTION_URL = `${API_URL}/inspection`
const GET_INSPECTION_URL = `${API_URL}/inspections`

const GET_BRANDS_URL = `${API_URL}/brands`
const GET_BRAND_MODEL_URL = `${API_URL}/brand`

const GET_COUNTRIES = `${API_URL}/countries`

const getInspection = (query: string): Promise<InspectionQueryResponse> => {
	return axios
		.get(`${GET_INSPECTION_URL}?${query}`)
		.then((d: AxiosResponse<InspectionQueryResponse>) => d.data)
}

const getInspectionById = (id: ID): Promise<Inspection | undefined> => {
	return axios
		.get(`${INSPECTION_URL}/${id}`)
		.then((response: AxiosResponse<ResponseInspectionById<Inspection>>) => response.data)
}

const createInspection = (inspection: Inspection): Promise<Inspection | undefined> => {
	return axios
		.post(INSPECTION_URL, inspection)
		.then((response: AxiosResponse<ResponseInspectionById<Inspection>>) => response.data)
}

const updateInspection = (inspection: Inspection): Promise<Inspection | undefined> => {
	return axios
		.put(`${INSPECTION_URL}/${inspection.id}`, inspection)
		.then((response: AxiosResponse<ResponseInspectionById<Inspection>>) => response.data)
}

const getListBrands = (): Promise<SelectModelQueryResponse> => {
	return axios
		.get(`${GET_BRANDS_URL}`)
		.then((response: AxiosResponse<SelectModelQueryResponse>) => response.data)
}

const getCountries = (): Promise<SelectModelQueryResponse> => {
	return axios
		.get(`${GET_COUNTRIES}`)
		.then((response: AxiosResponse<SelectModelQueryResponse>) => response.data)
}

export const getCountriesData = async (setData: (data: SelectModel[]) => void) => {
	const response = await axios.get<SelectModel[]>(GET_COUNTRIES);
	const clients = response.data;

	setData(clients);
};

const getBrandModel = (id: ID): Promise<SelectModelQueryResponse> => {
	return axios
		.get(`${GET_BRAND_MODEL_URL}/${id}/models`)
		.then((response: AxiosResponse<SelectModelQueryResponse>) => response.data)
}

const addMainImageInspection = (id: ID, mainImage: File): Promise<FileName | undefined> => {
	const formData = new FormData()
	formData.append("mainPhoto", mainImage)

	return axios
		.post(`${INSPECTION_URL}/${id}/main-image`, formData)
		.then((response: AxiosResponse<ResponseInspectionById<FileName>>) => response.data)
}

const deleteMainImageInspection = (id: ID): Promise<void> => {
	return axios.delete(`${INSPECTION_URL}/${id}/main-image`).then((response: InspectionConfirmResponse) => { })
}

const confirmInspection = (id: ID): Promise<ConfirmResponseModel | undefined> => {
	return axios.put(`${INSPECTION_URL}/${id}/close`)
		.then((d: Response<ConfirmResponseModel>) => d.data)
}

export { getCountries, getInspection, getInspectionById, createInspection, updateInspection, getListBrands, getBrandModel, addMainImageInspection, confirmInspection }
