import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse } from './core/QueryResponseProvider'
import { InspectionListHeader } from './components/header/InspectionListHeader'
import { InspectionListToolbar } from './components/header/InspectionListToolbar'

import { InspectionTable } from './table/InspectionTable'
import { KTCard } from '../../../../_metronic/helpers'
import { SmallAskModal } from './../../UI/SmallAskModal'
import mainClasses from './../../main.module.css'
import { updateInspection } from './core/_requests'

const InspectionList = () => {
	const { refetch } = useQueryResponse()

	const { openModalConfirmDeleteInspection, setOpenModalConfirmDeleteInspection } = useListView();

	const deleteUser = async () => {
		if (openModalConfirmDeleteInspection) {
			openModalConfirmDeleteInspection.status = 3
			await updateInspection(openModalConfirmDeleteInspection)
			setOpenModalConfirmDeleteInspection(undefined)

			refetch()
		}
	}

	return (
		<>
			<InspectionListHeader />

			<KTCard>
				<InspectionListToolbar />
				<InspectionTable />

				{openModalConfirmDeleteInspection !== undefined &&
					<SmallAskModal title={['Chcesz usunąć tę inspekcję?']} onClickNo={() => { setOpenModalConfirmDeleteInspection(undefined) }} onClickYes={deleteUser} titleYes="Usuń" />
				}
			</KTCard>
		</>
	)
}

const InspectionListWrapper = () => (
	<QueryRequestProvider>
		<QueryResponseProvider>
			<ListViewProvider>
				<InspectionList />
			</ListViewProvider>
		</QueryResponseProvider>
	</QueryRequestProvider>
)

export { InspectionListWrapper }
